<template>
  <div v-if="item">
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="发起调研流程"
      back-button-text="上一步"
      next-button-text="下一步"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- base information -->
      <tab-content title="调研基本信息" :before-change="validationFormBase">
        <validation-observer ref="datamapBase" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">调研基本信息</h5>
              <small class="text-muted"> DataMap 流程基础信息 </small>
            </b-col>

            <b-col md="4">
              <b-form-group label="发起人" label-for="name">
                <validation-provider #default="{ errors }" name="username">
                  <b-form-input
                    id="name"
                    v-model="item.ownerName"
                    :state="errors.length > 0 ? false : null"
                    placeholder="发起人"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="start"
                rules="required"
              >
                <b-form-group label="起始时间" label-for="start">
                  <flat-pickr
                    v-model="item.start"
                    class="form-control"
                    :config="{ time_24hr: true, mode: 'single' }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="end"
                rules="required"
              >
                <b-form-group label="结束时间" label-for="end">
                  <flat-pickr
                    v-model="item.end"
                    class="form-control"
                    :config="{ time_24hr: true, mode: 'single' }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12">
              <b-form-group label="标题" label-for="title">
                <validation-provider
                  #default="{ errors }"
                  name="title"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    v-model="item.title"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="简介" label-for="overview">
                <validation-provider
                  #default="{ errors }"
                  name="overview"
                  rules="required"
                >
                  <b-form-input
                    id="overview"
                    v-model="item.summary"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- org & users -->
      <tab-content title="选择参与部门" :before-change="validationFormOrg">
        <b-row>
          <b-col cols="4">
            <b-card title="选择参与部门">
              <b-table
                :items="orgList"
                :fields="orgFields"
                hover
                @row-clicked="orgRowClick"
              />
            </b-card>
          </b-col>
          <b-col cols="8">
            <b-card :title="orgUserListTitle">
              <b-table :items="orgUserList" :fields="orgUserFields">
                <template #cell(select)="data">
                  <b-form-checkbox v-model="data.item.selected" />
                </template>

                <template #cell(orgName)="data">
                  {{ getOrgName(data.item.orgId) }}
                </template>

                <template #cell(avatar)="data">
                  <b-img :src="data.item.avatar" width="48" rounded="circle" />
                </template>

                <template #cell(isManager)="data">
                  <b-form-checkbox
                    v-model="data.item.isManager"
                    @change="mangerChanged(data.item, $event)"
                    switch
                  />
                </template> </b-table
            ></b-card>
          </b-col>
        </b-row>
      </tab-content>

      <!-- template list -->
      <tab-content title="选择调研问卷" :before-change="validationFormSurvey">
        <b-table :items="surveyList" :fields="surveyFields">
          <template #cell(select)="data">
            <b-form-radio v-model="item.templateId" :value="data.item.id" />
          </template>
        </b-table>
      </tab-content>

      <!-- overview -->
      <tab-content title="发起调研流程">
        <h4 class="font-weight-bolder mb-1">调研基本信息</h4>
        <p>
          <span class="font-weight-bolder">{{ item.title }}</span>
        </p>
        <p>
          <span class="font-weight-bolder">{{ item.summary }}</span>
        </p>
        <p>
          <span class="font-weight-bolder">
            {{ item.start }} 至 {{ item.end }}
          </span>
        </p>
        <p>
          <span class="font-weight-bolder">发起人：</span>
          <span>{{ item.ownerName }}</span>
        </p>

        <h4 class="font-weight-bolder mt-3 mb-1">参与部门人员 ({{orgSelectedUsers().length}}人)</h4>
        <div v-for="(org, orgIdx) in orgList" :key="orgIdx">
          <p>
            <span class="font-weight-bolder">{{ org.name }}</span>
          </p>
          <p v-if="orgSelectedUsers(org.id).length==0">
            <span>无人参加</span>
          </p>
          <p v-else>
            <div v-for="(user, userIdx) in orgSelectedUsers(org.id)" :key="userIdx">
              <span>{{ user.nickName }} ({{user.email}})</span>
              <span v-if="user.isManager">(管理员)</span>
            </div>
          </p>
        </div>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BTable,
  BBadge,
  BFormCheckbox,
  BFormRadio,
  BMedia,
  BImg,
  BButton,
  BCard,
  BCardText,
} from "bootstrap-vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { required, email } from "@validations";

import { mapState, mapActions } from "vuex";

import flatPickr from "vue-flatpickr-component";
import { Mandarin } from "flatpickr/dist/l10n/zh.js";
flatpickr.localize(Mandarin);

export default {
  name: "DataMapProcessEdit",

  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BTable,
    BBadge,
    BFormCheckbox,
    BFormRadio,
    BMedia,
    BImg,
    BButton,
    BCard,
    BCardText,
    flatPickr,
  },

  data() {
    return {
      item: null,

      userList: [],

      roleList: [],

      // org list
      orgList: [],
      orgFields: [
        {
          key: "name",
          label: "名称",
        },
      ],

      // org user list
      orgUserListTitle: "选择参与部门成员",
      orgUserList: [],
      orgUserFields: [
        {
          key: "select",
          label: "选择",
        },
        {
          key: "avatar",
          label: "",
        },
        {
          key: "nickName",
          label: "用户名",
        },
        {
          key: "orgName",
          label: "部门",
        },
        {
          key: "email",
          label: "电邮",
        },
        {
          key: "isManager",
          label: "部门负责人",
        },
      ],

      // template list
      surveyList: [],
      surveyFields: [
        {
          key: "select",
          label: "选择",
        },
        {
          key: "title",
          label: "标题",
        },
        {
          key: "overview",
          label: "简介",
        },
        {
          key: "apply",
          label: "适用于",
        },
      ],

      //participants
      participantList: [],
    };
  },

  computed: {
    ...mapState({
      eid: (state) => state.appOptions.eid,
      user: (state) => state.appOptions.user,
    }),
  },

  created() {
    this.initItem();
  },

  methods: {
    initItem() {
      let id = this.$route.params.id;
      if (id !== "new") {
        this.loadItem();
      } else {
        this.item = {
          id: 0,
          eid: 0,
          templateId: 0,
          ownerName: "",
          ownerId: 0,
          title: null,
          summary: null,
          start: null,
          end: null,
          progress: 0,
          status: 1,
        };
      }

      this.loadOrgList();
      this.loadUserList();
      this.loadSurvyList();
      this.loadEID();
      this.loadUser();
      // this.loadParticipants(); // do it after item is loaded, so call it in loadItem()
      //this.mergeUsersWithParticipants(); //call it in loadUser() & loadParticipants()
    },

    loadItem() {
      let id = this.$route.params.id;
      this.$http.get("/datamaps/" + id).then((res) => {
        this.item = res.data.data;

        this.loadParticipants();
      });
    },

    loadRoleList() {
      this.$http.get("/roles").then((res) => {
        this.roleList = res.data.data;
      });
    },

    loadOrgList() {
      this.$http.get("/organizations").then((res) => {
        this.orgList = res.data.data.list.filter((item) => {
          return item.root != 1;
        });
      });
    },

    loadUserList() {
      this.$http.get("/users").then((res) => {
        this.userList = res.data.data.list;
        this.userList.forEach((item) => {
          item.selected = false;
        });
        this.mergeUsersWithParticipants();
        this.userList.sort((a, b) => {
          return a.orgId - b.orgId;
        });
      });
    },

    loadRoleUserList(roleId) {
      this.$http.get("/roleusers/" + roleId).then((res) => {
        this.userList = res.data.data;
      });
    },

    loadSurvyList() {
      this.$http.get("/libsurveys").then((res) => {
        this.surveyList = res.data.data.list;
      });
    },

    loadParticipants() {
      if (this.item && this.item.id) {
        let params = {
          surveyInstanceId: this.item.id,
        };
        this.$http.get("/datamapparticipants", { params }).then((res) => {
          this.participantList = res.data.data.list;

          this.mergeUsersWithParticipants();
        });
      }
    },

    mergeUsersWithParticipants() {
      // debugger
      if (
        this.userList &&
        this.userList.length &&
        this.participantList &&
        this.participantList.length
      ) {
        console.log("mergeUsersWithParticipants");
        //merge user list with participant list
        this.participantList.forEach((item) => {
          let user = this.userList.find((user) => {
            return user.id == item.operatorId;
          });
          if (user) {
            // console.log("merge user: " + user.nickName, user);
            user.selected = true;
            user.isManager = item.isManager ? true : false;
          }
        });
      }
    },

    setTemplateId(id) {
      this.item.templateId = id;
    },

    getOrgName(orgId) {
      // console.log(orgId);
      let org = this.orgList.find((item) => {
        return item.id === orgId;
      });
      return org ? org.name : "";
    },

    mangerChanged(user, isManager) {
      let orgId = user.orgId;
      let userId = user.id;
      if (isManager) {
        this.userList.forEach((item) => {
          if (item.orgId === orgId && item.id !== userId) {
            item.isManager = false;
          }
        });
      }
    },

    // filter users by org id
    orgUsers(orgId) {
      return this.userList.filter((user) => {
        return user.orgId === orgId;
      });
    },

    // selected org user list
    orgSelectedUsers(orgId) {
      return this.userList.filter((user) => {
        return orgId ? user.orgId === orgId && user.selected : user.selected;
      });
    },

    validationForm(ref) {
      return new Promise((resolve, reject) => {
        this.$refs[ref].validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    validationFormBase() {
      return this.validationForm("datamapBase");
    },

    validationFormOrg() {
      return new Promise((resolve, reject) => {
        let selected = this.userList.find((item) => {
          return item.selected;
        });
        if (selected) {
          resolve(true);
        } else {
          reject();
        }
      });
    },

    validationFormSurvey() {
      return new Promise((resolve, reject) => {
        let selected = this.item.templateId > 0;
        if (selected) {
          resolve(true);
        } else {
          reject();
        }
      });
    },

    orgRowClick(item) {
      let orgId = item.id;
      this.orgUserList = this.userList.filter((user) => {
        return user.orgId === orgId;
      });

      this.orgUserListTitle = "选择参与部门成员 - " + item.name;
    },

    formSubmitted() {
      // debugger;
      // save base info
      if (this.item.id === 0) {
        this.item.eid = this.eid;
        this.item.ownerName = this.user.nickName;
        this.item.ownerId = this.user.ownerId;

        this.$http.post("/datamaps", this.item).then((res) => {
          this.item = res.data.data;

          this.$bvToast.toast("基础信息保存成功", {
            title: "保存成功",
            variant: "success",
            solid: true,
            autoHideDelay: 2000,
          });

          this.saveParticipants();
          this.sendEmails();
          this.sendNotifications();
          this.$router.push("/datamap-process");
        });
      } else {
        this.$http.put("/datamaps/" + this.item.id, this.item).then((res) => {
          this.$bvToast.toast("基础信息保存成功", {
            title: "保存成功",
            variant: "success",
            solid: true,
            autoHideDelay: 2000,
          });

          this.saveParticipants();
          this.sendEmails();
          this.sendNotifications();
          this.$router.push("/datamap-process");
        });
      }
    },

    saveParticipants() {
      // save org info
      let users = this.userList.filter((user) => {
        return user.selected;
      });
      let participants = [];

      users.forEach((user) => {
        let participant = {
          eid: this.eid,
          orgId: user.orgId,
          surveyInstanceId: this.item.id,
          operatorId: user.id,
          operatorName: user.nickName,
          isManager: user.isManager ? 1 : 0,
          status: 1,
        };
        participants.push(participant);
      });

      // console.log(participants);
      this.$http.post("/datamapparticipants", participants);
    },

    sendEmails() {
      let users = this.userList.filter((user) => {
        return user.selected;
      });

      //send mail
      let mailContent =
        `From ${this.item.start} to ${this.item.end}， fill the datamap \r\n` +
        `https://ent.zhenhecai.com/${this.eid}/datamap-file/${this.item.id}`;
      let mailList = [];
      users.forEach((user) => {
        mailList.push(user.email);
      });
      this.$http.post("/sendemail", {
        sender: this.user.nickName,
        subject: "Start Datamap",
        body: mailContent,
        to: mailList,
      });
    },

    sendNotifications() {
      let users = this.userList.filter((user) => {
        return user.selected;
      });

      let content =
        `From ${this.item.start} to ${this.item.end}， fill the datamap \r\n` +
        `<a href="https://ent.zhenhecai.com/${this.eid}/datamap-file/${this.item.id}">${this.item.title}</a>`;
      users.forEach((user) => {
        this.$http.post("/notifications", {
          eid: this.eid,
          title: "Start Datamap",
          content: content,
          status: 1,
          type: 0,
          targetUid: user.id,
          targetOrgId: 0,
        });
      });
    },

    ...mapActions({
      loadEID: "appOptions/LOAD_EID",
      loadUser: "appOptions/LOAD_USER",
    }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
